import React from "react";

const East = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <p>From The East</p>
          <p>"Meet Upon the Level and Part Upon the Square"</p>
        </div>
        <div className="card-body">
          <p>
            As the crisp autumn air settles in, we're excited to bring you the
            latest updates, events, and highlights from our Masonic community.
            October is a time of reflection and growth, and we have plenty of
            news to share that reflects our ongoing journey together.
          </p>
          <p>
            Brethren, I would like to thank everyone who participated in the
            Edward Page Henry School held at our Lodge on September 23-25.
          </p>
          <p>
            We had a great turn out for all three nights. The Craftsman's
            Club/Brother Drew did an excellent job with providing the
            refreshments for all 3 nights.
          </p>
          <p>
            Much needed work has been done on the Lodge property. It has been
            mowed, boundary markers highlighted. We still have many holes and
            depressions to fill, the driveway entrance to widen and stumps to
            burn and bury. Box Blade Tractors, Backhoes and Excavators are
            needed to do this type of heavy work. So if you have any equipment
            you would like to put to work or even loan let us know!
          </p>
          <p>
            Coming up is MAHOVA Family Day Saturday, October 5th from 8 am - 1
            pm. Location 500 Masonic Ln, Richmond, VA 23223 USA.
          </p>
          <p>
            We will be doing the first responders breakfast on Monday October
            28th from 6 am to 8 am. So come out and help to prepare and serve
            meals. This is always a good way to show how much our Fraternity
            appreciates and supports them.
          </p>
          <p>
            Our final blood drive of 2024 is October 29th from 1 pm - 6 pm. Many
            slots available from 1-2:15 and 3-5:45. To schedule a donation,
            please contact blood coordinator Ford Pugh or visit the Red Cross
            Sign Up.
          </p>
          <p>
            As we embrace the changes October brings, let us continue to support
            and inspire one another. We're grateful for your engagement and
            contribution to our community. Here's to a productive and fulfilling
            month ahead!
          </p>
        </div>
        <div className="card-footer">
          <p>William "Bill" Coyner</p>
          <p>Worshipful Master, PG Lodge #115</p>
        </div>
      </div>
    </>
  );
};

export default East;
